import firestore from '@/components/utils/firebase'

const createUser = async (id, data) => {
  await firestore
    .collection('USER')
    .doc(id)
    .set({ ...data }, { merge: true })
}

const getUser = async (id) => {
  let user = await firestore.collection('USER').doc(id).get()
  user = user.exists ? user.data() : null
  return user
}

const updateUser = async (id, data) => {
  await firestore
    .collection('USER')
    .doc(id)
    .set({ ...data }, { merge: true })
}

const getUserGoogleTokens = async (id) => {
  try {
    let tokens = await firestore.collection('USER').doc(id).collection('SECRET').doc('google').get()
    tokens = tokens.exists ? tokens.data() : null
    return tokens
  } catch (err) {
    throw `${err}`
  }
}

const getUserMicrosoftTokens = async (id) => {
  let tokens = await firestore
    .collection('USER')
    .doc(id)
    .collection('SECRET')
    .doc('microsoft')
    .get()
  tokens = tokens.exists ? tokens.data() : null
  return tokens
}
const getUserZoomTokens = async (id) => {
  let tokens = await firestore.collection('USER').doc(id).collection('SECRET').doc('zoom').get()
  tokens = tokens.exists ? tokens.data() : null
  return tokens
}
const deleteUserZoomTokens = async (id) => {
  await firestore.collection('USER').doc(id).collection('SECRET').doc('zoom').delete()
}

const updateUserGoogleTokens = async (id, tokens) => {
  await firestore
    .collection('USER')
    .doc(id)
    .collection('SECRET')
    .doc('google')
    .set({ ...tokens }, { merge: true })
}

const updateUserMicrosoftTokens = async (id, tokens) => {
  await firestore
    .collection('USER')
    .doc(id)
    .collection('SECRET')
    .doc('microsoft')
    .set({ ...tokens }, { merge: true })
}

const deleteUserMicrosoftTokens = async (id) => {
  await firestore.collection('USER').doc(id).collection('SECRET').doc('microsoft').delete()
}

const updateUserZoomTokens = async (id, tokens) => {
  await firestore
    .collection('USER')
    .doc(id)
    .collection('SECRET')
    .doc('zoom')
    .set({ ...tokens }, { merge: true })
}

export {
  createUser,
  getUser,
  updateUser,
  getUserGoogleTokens,
  updateUserGoogleTokens,
  updateUserMicrosoftTokens,
  updateUserZoomTokens,
  getUserMicrosoftTokens,
  deleteUserMicrosoftTokens,
  getUserZoomTokens,
  deleteUserZoomTokens
}
