import {
  createUser,
  updateUser,
  updateUserMicrosoftTokens,
  updateUserZoomTokens
} from '@/entities/user'
import { identifyTrackedUser, signUpForTracking } from '@/components/utils/eventTracker'

export async function signUp(user, invitedEmail) {
  let userObj = {
    uid: user.uid,
    name: user.displayName ? user.displayName : user.email.split('@')[0],
    profile: 'No Profile',
    iconURL: user.photoURL,
    email: user.email,
    isAnonymous: false,
    lastSignInTime: user.metadata.lastSignInTime,
    createdAt: user.metadata.creationTime,
    signUpAt: new Date(),
    timeZone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Asia/Tokyo'
  }
  if (invitedEmail) {
    userObj.invitedEmail = this.$route.params.invitedEmail
  }
  await createUser(user.uid, userObj)

  identifyTrackedUser(user.uid)
  signUpForTracking(userObj)
}

export async function signIn(user, userDoc) {
  await Promise.all([
    updateUser(user.uid, {
      name: user.displayName || userDoc.name,
      iconURL: user.photoURL || userDoc.iconURL,
      email: user.email || userDoc.email
    })
  ])
  identifyTrackedUser(user.uid)
}

export const TokenUpdateFn = {
  // google: updateUserGoogleTokens,
  'microsoft.com': updateUserMicrosoftTokens
  // 'zoom.us': updateUserZoomTokens
}

export async function processSignInSuccess({ user, token, invitedEmail, provider }) { // eslint-disable-line
  // const userDoc = await getUser(user.uid)
  // if (!userDoc) {
  //   await signUp(user, invitedEmail)
  // } else {
  //   await signIn(user, userDoc)
  // }

  let tokens = {
    createdAt: new Date(),
    createdBy: user.uid,
    accessToken: token.accessToken,
    idToken: token.idToken || null,
    scope: token.scope || null,
    tenantId: token.tenantId || null
  }
  if (token.refreshToken !== undefined) {
    tokens.refreshToken = token.refreshToken
  }

  const updateFn = TokenUpdateFn[provider]
  if (!updateFn) {
    console.log('provider', provider, 'not supported locally')
    return
  }
  await updateFn(user.uid, tokens)
}
