import firebase from 'firebase/app'

export async function signInWithMicrosoft(link = false) {
  const provider = new firebase.auth.OAuthProvider('microsoft.com')
  provider.addScope('calendars.readwrite')
  provider.addScope('OnlineMeetings.ReadWrite')
  provider.addScope('offline_access')
  provider.setCustomParameters({
    promt: 'consent'
  })

  const auth = firebase.auth()
  let user = auth.currentUser
  if (user && link) {
    console.log('Linking with current user', user.uid)
    user.linkWithRedirect(provider)
  } else {
    console.log('Signing in using microsoft')
    auth.signInWithRedirect(provider)
  }
}

export async function unlinkFromMicrosoft(client) {
  if (!client.auth()) {
    return
  }

  const user = client.auth().currentUser
  if (!user) {
    console.log('User hasn\'t signin yet') // eslint-disable-line
    return
  }

  return user.unlink('microsoft.com')
}
