<template lang="pug">
  a(@click="signInWIthMicrosoft" v-if="!loading").btn-microsoft.f.fm.flex-between
    div.f.fm
      v-img(src="/icons/microsoft.svg" width="36").btn-img.mr8
      span.fz14 Microsoft
    span.fz14 {{microsoftAccountIsConnected ? '連携解除' : '連携開始'}}
  a(v-else).btn-microsoft.f.fm.px16.py10
    v-progress-circular(indeterminate color="primary").mr16
    span.fz14 処理中
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.btn-microsoft {
  width: 100%;
  border-radius: 3px;
  background: $primary_bg_color;
  span {
    color: $secondary_text_color;
  }
}
.btn-img {
  width: 24px;
}
</style>

<script>
import { signInWithMicrosoft } from '@/components/utils/microsoft'
import firebase from 'firebase/app'
import { processSignInSuccess } from '@/components/utils/user'
import { processMicrosoftIdToken } from '@/services/microsoft'
import { getUserMicrosoftTokens, deleteUserMicrosoftTokens } from '@/entities/user'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  name: 'MicrosoftSignIn',
  props: {},
  computed: {
    ...mapStateAuth(['uid'])
  },
  data: () => ({
    authURL: null,
    loading: false,
    microsoftAccountIsConnected: false
  }),
  methods: {
    signInWIthMicrosoft: async function () {
      if (!this.microsoftAccountIsConnected) {
        this.loading = true
        await firebase
          .auth()
          .currentUser.unlink('microsoft.com')
          .catch((e) => console.warn(e))
        await signInWithMicrosoft(true)
      } else {
        this.loading = true
        await firebase
          .auth()
          .currentUser.unlink('microsoft.com')
          .catch((e) => console.warn(e))
        await deleteUserMicrosoftTokens(this.uid)
        this.microsoftAccountIsConnected = false
        this.loading = false
      }
    },
    isMicrosoftLinked: async function () {
      return !!(await getUserMicrosoftTokens(this.uid).catch(() => false))
    },
    prepareMicrosoftSignIn: async function () {
      const result = await firebase.auth().getRedirectResult()
      if (!result) {
        this.loading = false
        console.log('microsoft: auth result is not available')
        return
      }
      if (!result.credential) {
        console.log('microsoft: credential not available')
        this.loading = false
        return
      }
      if (result.credential.providerId !== 'microsoft.com') {
        this.loading = false
        console.log('microsoft: credential not from microsoft', result.credential)
        return
      }

      const { tokens, tenantId } = await processMicrosoftIdToken(result.credential).then(
        (result) => {
          return result.data
        }
      )
      console.log('microsoft: processing sign in')
      this.microsoftAccountIsConnected = true
      await processSignInSuccess({
        user: result.user,
        token: {
          accessToken: tokens.access_token,
          refreshToken: tokens.refresh_token,
          expiresIn: tokens.expires_in,
          scope: tokens.scope,
          tokenType: tokens.token_type,
          extExpiresIn: tokens.ext_expires_in,
          tenantId
        },
        provider: 'microsoft.com',
        invitedEmail: this.$route.params.invitedEmail
      })
    }
  },
  async created() {
    this.loading = true
    this.microsoftAccountIsConnected = await this.isMicrosoftLinked()

    if (!this.microsoftAccountIsConnected) {
      await this.prepareMicrosoftSignIn()
    }

    this.loading = false

    //   await this.signInSuccess({ firebaseUser: user, token: tokens })
    //   this.$router.replace('/home')
  }
}
</script>
