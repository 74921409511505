<template lang="pug">
  a(v-if="!loading && !zoomAccountIsConnected" :href="authURL" ).btn-zoom.f.fm.flex-between
    div.f.fm
      v-img(src="/icons/zoom.svg" width="36").btn-img.mr8
      span.fz14 Zoom
    span.fz14 連携開始
  a(v-else-if="!loading && zoomAccountIsConnected" @click="unlinkZoom").btn-zoom.f.fm.flex-between
    div.f.fm
      v-img(
        src="/icons/zoom.svg"
        width="36").mr8.btn-img
      span.fz14 Zoom
    span.fz14 連携解除
  a(v-else="loading").btn-zoom.f.fm.px16.py10
    v-progress-circular(indeterminate color="primary").mr16
    span.fz14 処理中

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.btn-zoom {
  width: 100%;
  border-radius: 3px;
  background: $primary_bg_color;
  span {
    color: $secondary_text_color;
  }
}
</style>

<script>
import { generateZoomAppInstallUrl } from '@/components/utils/zoom'
import { getUserZoomTokens, deleteUserZoomTokens } from '@/entities/user'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  name: 'ZoomSignIn',
  props: {},
  computed: {
    ...mapStateAuth(['uid'])
  },
  data: () => ({
    zoomAccountIsConnected: false,
    authURL: null,
    loading: false
  }),
  methods: {
    unlinkZoom: async function () {
      this.loading = true
      await deleteUserZoomTokens(this.uid)
      this.zoomAccountIsConnected = false
      this.loading = false
    },
    isZoomLinked: async function () {
      return !!(await getUserZoomTokens(this.uid).catch(() => false))
    }
  },
  async mounted() {
    this.loading = true
    this.zoomAccountIsConnected = await this.isZoomLinked()
    this.authURL = generateZoomAppInstallUrl(window.location.pathname)
    console.log('zoom auth url', this.authURL)

    this.loading = false
  }
}
</script>
