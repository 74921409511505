import { stringify } from 'querystring'
import json from 'fast-json-stable-stringify'
export function generateZoomAppInstallUrl(origin) {
  const query = {
    response_type: 'code',
    redirect_uri: `${process.env.VUE_APP_DOMAIN}/auth-callback/zoom`,
    client_id: process.env.VUE_APP_ZOOM_CONFIG_CLIENT_ID,
    state: json({ origin })
  }
  return `https://zoom.us/oauth/authorize?${stringify(query)}`
}
